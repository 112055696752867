.chatWindow {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.chatWindow-header {
	height: 60px;
	border-bottom: 1px solid #e21251;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.chatWindow-headerinfo {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.chatWindow-avatar {
	width: 40px;
	height: 40px;
	border-radius: 20px;
	margin-left: 15px;
	margin-right: 15px;
}

.chatWindow-name {
	font-size: 17px;
	color: black;
}

.chatWindow-body {
	flex: 1;
	overflow-y: auto;
	background-size: cover;
	background-position: center;
	background-color: #f5f5f5;
	padding: 20px 30px;
}

.chatWindow-body::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

.chatWindow-body::-webkit-scrollbar-thumb {
	background-color: #e2125033;
	border-radius: 3px;
}

.chatWindow-btn {
	width: 40px;
	height: 40px;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.chatWindow-footer {
	min-height: 50px;
	padding-top: 5px;
	display: flex;
	align-items: center;
}

#chat-input {
	resize: none;
}

.chatWindow-inputarea {
	flex: 1;
	padding-left: 15px;
}

.chatWindow-input {
	width: 100%;
	min-height: 30px;
	border: 0;
	outline: 0;
	background-color: #fff;
	border-radius: 20px;
	font-size: 15px;
	color: #4a4a4a;
	padding-left: 15px;
}

.chatWindow-pos {
	display: flex;
	margin: 0px 15px;
}

.chatWindow-refresh {
	margin-bottom: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	color: #e21251;
	cursor: pointer;
}
