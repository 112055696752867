.chatIntro{
    background-color: #F8F9FA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 6px solid #e21251;
    height: 100vh;
}

.chatIntro img{
    width: 250;
    height: auto;
}

.chatIntro h1{
    font-size: 32px;
    color: #525252;
    font-weight: normal;
    margin-top: 30px;
}