.messageLine{
    margin-bottom: 15px;
    display: flex;
}

.messageItem{
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0px 1px 1px #CCC;
    display: flex;
    flex-direction: column;
    padding: 3px;
    max-width: 50%;
    min-width: 200px;
}

.messageText{
    font-size: 14px;
    margin: 5px 70px 5px 5px;
    white-space: pre-wrap;
}

.messageDate{
    font-size: 11px;
    color: #999;
    margin-right: 5px;
    text-align: right;
    height: 15px;
    margin-top: -5;
}