// change the theme
$theme-colors: (
  "primary": #e21251,
  "danger": #fd7e14
);


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.login-card {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 10px rgba(0, 0, 0, 0.075);
    font-weight: bold;
}

.btn{
    font-weight: bold;
    color: #e21251;
    background-color: transparent;
    border: 2px solid #e21251;
}

.login-card a{
    color: #e21251;
    text-decoration: none;
}

.form-control:focus {
    border-color: #e21251;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #e21251;
}
